import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../../assets/images/logo.jpg'
import phone from '../../assets/images/call.svg'
import search from '../../assets/images/whatsapp.png'
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header className="header">
      <Navbar expand="md">
        <Container>
          <Navbar.Brand>
            <Link className='nav-link' to="/">
              <img className='logo' src={logo} alt='logo' />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Link className='nav-link' to="/"> Home </Link>
              <Link className='nav-link' to="/aboutus"> About Us </Link>
              <Link className='nav-link' to="/ourproducts"> Our Products </Link>
              <div className='icons'>
                <a href='tel:971522957567' ><img src={phone} alt='phoneicon'  /></a>
                <a  href="//api.whatsapp.com/send?phone=971556496307&text=Hello Dezwell" ><img src={search} alt='watsapicon' /></a>
              </div>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}