import Header from '../comon/header';
import Footer from '../comon/footer'; import Accordion from 'react-bootstrap/Accordion';
import bannerimg from '../../assets/images/productsbanner.jpg'
import filtericon from '../../assets/images/filter.png';
import productData from '../comon/productdata.json';
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';



export default function Ourproducts() {
    const [data, setData] = useState(null);
    const [show, setShow] = useState(false);
    const [productid, setProductId] = useState("")
    const [price, setPrice] = useState("");
    const [size, setSize] = useState("");

    useEffect(() => {
        console.log((window.location.href).split('#')[0])
        setData(productData?.products)
    }, [])


    const [searchTerm, setSearchTerm] = useState('');
    const [categoryFilters, setCategoryFilters] = useState([]);
    const [nameFilters, setNameFilters] = useState([]);

    const handleCategoryChange = (event) => {
        const { id, checked } = event.target;
        if (checked) {
            setCategoryFilters([...categoryFilters, id]);
        } else {
            setCategoryFilters(categoryFilters.filter(filter => filter !== id));
        }
    };

    const handleNameChange = (event) => {
        const { id, checked } = event.target;
        if (checked) {
            setNameFilters([...nameFilters, id]);
        } else {
            setNameFilters(nameFilters.filter(filter => filter !== id));
        }
    };

    const filteredData = data?.filter(item => {
        // Filter by search term
        const searchTermMatch = item.name.toLowerCase().includes(searchTerm.toLowerCase());

        // Filter by category
        const categoryMatch = categoryFilters.length === 0 || categoryFilters.includes(item.device);

        // Filter by name
        const nameMatch = nameFilters.length === 0 || nameFilters.includes(item.brand);

        return searchTermMatch && categoryMatch && nameMatch;
    });

    const handleClose = () => {
        setPrice("")
        setShow(false)
    };

    const productClick = (e) => {
        // console.log("xxxxxxx", e?.target?.getAttribute("data-id"))
        setShow(true)
        setProductId(e?.target?.getAttribute("data-id"))

        var selectedData = productData?.products?.filter((x) => x?.id === e?.target?.getAttribute("data-id"))
        setPrice(selectedData[0]?.storage[0]?.price)
        setSize(selectedData[0]?.storage[0]?.size)
    }

    const sizeSwitcher = (e) => {
        setPrice(e?.target?.value)
        setSize(e?.target?.getAttribute("data-size"))
    }

    useEffect(() => {
        console.log(price)
    }, [price])

    useEffect(() => {
        setProductId(productid)
    }, [productid])

    return (
        <div>
            <Header />
            <section className='banner-section small'>
                <img className='banner-img' alt='bannerimage' src={bannerimg} />
                <div className='banner-details'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <h1 className='title'>
                                    <span className='bottom'>Our Products</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='product-listing'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <Accordion defaultActiveKey="2" className='acc-parent'>
                                <Accordion.Item eventKey="0" >
                                    <Accordion.Header><img className='filter-icon' src={filtericon} alt='filtericon' />Filter by</Accordion.Header>
                                    <Accordion.Body>
                                        <input className='form-search'
                                            type="text"
                                            placeholder="Search by Name"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                        />
                                        <div className='filter-sidebar'>
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0" >
                                                    <Accordion.Header>Category</Accordion.Header>
                                                    <Accordion.Body>
                                                        <ul className='m-0 p-0'>
                                                            <li><Form.Check
                                                                type={'checkbox'}
                                                                id={`laptop`}
                                                                label={`Laptops`}
                                                                name="Laptops"
                                                                checked={categoryFilters.includes("laptop")}
                                                                onChange={handleCategoryChange}
                                                            /></li>
                                                            <li><Form.Check
                                                                type={'checkbox'}
                                                                id={`smartphone`}
                                                                label={`Smartphones`}
                                                                checked={categoryFilters.includes("smartphone")}
                                                                onChange={handleCategoryChange}
                                                            /></li>
                                                            <li><Form.Check
                                                                type={'checkbox'}
                                                                id={`tablet`}
                                                                label={`Tablets`}
                                                                checked={categoryFilters.includes("tablet")}
                                                                onChange={handleCategoryChange}
                                                            /></li>
                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Brand</Accordion.Header>
                                                    <Accordion.Body>
                                                        <ul className='m-0 p-0'>
                                                            <li><Form.Check className='branch-checkbox'
                                                                type={'checkbox'}
                                                                id={`Apple`}
                                                                label={`Apple`}
                                                                checked={nameFilters.includes("Apple")}
                                                                onChange={handleNameChange}
                                                            /></li>

                                                            <li><Form.Check className='branch-checkbox'
                                                                type={'checkbox'}
                                                                id={`hp`}
                                                                label={`hp`}
                                                                checked={nameFilters.includes("hp")}
                                                                onChange={handleNameChange}
                                                            /></li>
                                                            <li><Form.Check
                                                                type={'checkbox'}
                                                                id={`dell`}
                                                                label={`dell`}
                                                                checked={nameFilters.includes("dell")}
                                                                onChange={handleNameChange}
                                                            /></li>
                                                            <li><Form.Check
                                                                type={'checkbox'}
                                                                id={`lenovo`}
                                                                label={`lenovo`}
                                                                checked={nameFilters.includes("lenovo")}
                                                                onChange={handleNameChange}
                                                            /></li>
                                                            <li><Form.Check
                                                                type={'checkbox'}
                                                                id={`samsung`}
                                                                label={`Samsung`}
                                                                checked={nameFilters.includes("samsung")}
                                                                onChange={handleNameChange}
                                                            /></li>
                                                        </ul>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                            {/*  } */}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                        </div>
                        <div className='col-lg-9'>
                            <div className='row'>
                                <div className='col-sm-12'>
                                    {filteredData && filteredData?.length === 0 ? <div className='result'>No Products Found</div> :
                                        <div className='result'>Showing <span>{filteredData?.length} Products</span></div>
                                    }
                                </div>

                                {
                                    filteredData && filteredData?.map((i) => (
                                        <div className='col-sm-6 col-md-4 stretch-card' key={i?.id} id={i?.id} onClick={productClick} data-id={i?.id}>
                                            <div className='product' data-id={i?.id}>
                                                <div className='card-body' data-id={i?.id}>
                                                    <div className='img-outer' data-id={i?.id}>
                                                        {
                                                            <img data-id={i?.id} className='img-fluid' src={process.env.REACT_APP_WINDOWURL+`/productimages/${i?.imgsrc}`} alt='' />
                                                        }

                                                    </div>
                                                    <div data-id={i?.id} className='product-name'>{i?.name}</div>
                                                    <div data-id={i?.id} className='product-spec'>{i?.year && i?.year + " |"} {i?.screensize} Inch</div>
                                                    <div data-id={i?.id} className='product-price'><span>AED</span> {i?.price}.00</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <Modal className='product-modal' size='lg' centered show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    {
                        productData && productData.products.filter((x) => x?.id === productid).map((i) => (
                            <Modal.Body>
                                <div className='img-outer'>
                                <img data-id={i?.id} className='img-fluid' src={process.env.REACT_APP_WINDOWURL+`/productimages/${i?.imgsrc}`} alt='' />
                                </div>
                                <div className='details'>
                                    <div className='title-outer'>
                                        <div class="product-name">{i?.name}</div>
                                    </div>
                                    <div class="product-price"><small>AED</small> {price}</div>
                                    {i?.storage &&
                                        <div>
                                            <div className='sec-title'>Size: <span>{size}</span></div>
                                            <ul className='size'>
                                                {
                                                    i?.storage.map((st) => (
                                                        <li value={st?.price} data-size={st?.size} onClick={sizeSwitcher}>{st?.size}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    }

                                    {i?.color &&
                                        <div>
                                            <div className='sec-title'>Colour: <span>{i?.color[0]}</span></div>
                                            <ul className='color'>
                                                {i?.color.map((el) => (
                                                    <li >{el}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    }
                                    <ul className='spec'>
                                        {i?.brand && <li><span>Brand</span> {i?.brand}</li>}
                                        {i?.name && <li><span>Modal Name</span> {i?.name}</li>}
                                        {i?.brand && <li><span>Operating System</span>{i?.brand === "Apple" ? " IOS" : " Windows"} </li>}
                                        {i?.screensize && <li><span>Display</span> {i?.screensize} Inch</li>}
                                        {i?.year && <li><span>Year</span> {i?.year}</li>}
                                    </ul>
                                </div>

                            </Modal.Body>
                        ))
                    }
                </Modal>
            </section >

            <Footer />
        </div >
    )
}