import Header from '../comon/header';
import Footer from '../comon/footer';
import bannerimg from '../../assets/images/aboutusbanner.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import icon from '../../assets/images/apple.svg'
import icon1 from '../../assets/images/hp.svg'
import icon2 from '../../assets/images/dell.svg'
import icon3 from '../../assets/images/lenovo.png'
import icon4 from '../../assets/images/asus.png'
import icon5 from '../../assets/images/acer.png'
import whoimg from '../../assets/images/whowe.jpg'

import 'swiper/css';
import { Autoplay } from 'swiper/modules';

export default function Aboutus() {
    return (
        <div>
            <Header />
            <section className='banner-section small'>
                <img className='banner-img' alt='bannerimage' src={bannerimg} />
                <div className='banner-details'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <h1 className='title'>
                                    <span className='bottom'>About Us</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='blockquote-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='title-section'>
                                <div className='title'>Who We Are</div>
                            </div>
                            <div className='quote'>Discover premium performance at unbeatable prices
                                with our refurbished laptops. At Dezwell, we meticulously refurbish each device to deliver like-new functionality, ensuring reliability and satisfaction with every purchase. From sleek ultrabooks to powerful workstations, our diverse selection caters to every need and budget. Our rigorous testing process guarantees top-notch quality, backed by warranty for added peace of mind. Embrace sustainability by choosing refurbished - not only do you save money, but you also contribute to reducing electronic waste. Experience the perfect blend of affordability and excellence with Dezwell, your trusted destination for high-quality refurbished laptops.</div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='whatwedo-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='title-section'>
                                <div className='title'>Our Offerings.</div>
                            </div>
                        </div>
                        <div className='col-sm-12'>
                            <div className='details'>
                                <div className=''>
                                    <div className='subtitle'>Quality Refurbishment</div>
                                    <p>
                                        Through a meticulous three-tier quality and compliance process, Beefurb extends device lifespans, promoting safer, affordable technology choices.</p>
                                    <div className='subtitle'>Responsible Enterprise</div>
                                    <p>Dezwell fosters sustainable development, responsibly managing e-waste, cost-effectively bridging the digital divide, and serving diverse global clients.</p>
                                </div>
                                <div className=''>
                                    <img className='img-fluid' src={whoimg} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='brands-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='title-section'>
                                <div className='title'>Brands We Deal.</div>
                            </div>

                            <div className='carousal-outer'>
                                <Swiper
                                    slidesPerView={'auto'}
                                    spaceBetween={30}
                                    speed={5000}
                                    loop={true}
                                    autoplay={{
                                        delay: 0,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide><img className='slider-img' src={icon} alt='' /></SwiperSlide>
                                    <SwiperSlide><img className='slider-img' src={icon3} alt='' /></SwiperSlide>
                                    <SwiperSlide><img className='slider-img' src={icon1} alt='' /></SwiperSlide>
                                    <SwiperSlide><img className='slider-img' src={icon4} alt='' /></SwiperSlide>
                                    <SwiperSlide><img className='slider-img' src={icon2} alt='' /></SwiperSlide>
                                    <SwiperSlide><img className='slider-img' src={icon5} alt='' /></SwiperSlide>
                                </Swiper>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}