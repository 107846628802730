import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.jpg';
import facebook from '../../assets/images/facebook.svg';
import instagram from '../../assets/images/insta.svg';
import linkedin from '../../assets/images/linkedin.svg';
import twitter from '../../assets/images/twitter.svg';
import watsap from '../../assets/images/whatsapp.png';

export default function Footer() {
    return (
        <div>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="top-section">
                                
                                <div className='quick-links'>
                                <div className="brand-section">
                                    <img src={logo} alt='logo' />
                                </div>
                                    <div>
                                        <div className='title'>Quick Links</div>
                                        <ul>
                                            <li>
                                                <Link to="/" >Home</Link>
                                            </li>
                                            <li>
                                                <Link to="/" >About us</Link>
                                            </li>
                                            <li>
                                                <Link to="/" >Our Products</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div className='title'>Products</div>
                                        <ul>
                                            <li>
                                                <Link to="/" >Smartphones</Link>
                                            </li>
                                            <li>
                                                <Link to="/" >Laptops</Link>
                                            </li>
                                            <li>
                                                <Link to="/" >Tablets</Link>
                                            </li>
                                            <li>
                                                <Link to="/" >Desktops</Link>
                                            </li>
                                            <li>
                                                <Link to="/" >Accessories</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div className='title'>Contact Us</div>
                                        <ul>
                                            <li className='social'><a href="//api.whatsapp.com/send?phone=971556496307&text=Hello Dezwell"><img src={watsap} alt='watsapimg' /> +971 556496307</a></li>
                                        </ul>
                                    </div>
                                    <div>
                                        <div className='title'>Follow Us</div>
                                        <ul>
                                            <li className='social'>
                                                <a target='_blank' href="https://www.facebook.com/DezwellComputerStore?mibextid=ZbWKwL" rel='noreferrer'><img src={facebook} alt='facebookimg' /></a>
                                                <a target='_blank' href="https://www.instagram.com/dezwell/"  rel='noreferrer'><img src={instagram} alt='instaicon' /></a>
                                                <Link to="/" ><img src={twitter} alt='twittericon' /></Link>
                                                <Link to="/" ><img src={linkedin} alt='linkedinicon' /></Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="bottom-section">
                                <p>© 2024 Dezwell</p>
                                <p>Powered by <a target='_blank' href='https://www.linkedin.com/in/asif-sabu-303709176/' rel='noreferrer'>Asif</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}