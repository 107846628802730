import Home from './modules/pages/index';
import Aboutus from './modules/pages/aboutus';
import Ourproducts from './modules/pages/ourproducts';
import './App.css';

import { HashRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    <div className='content-wrapper'>
      <HashRouter >
        <Routes>
          <Route path={"/"} element={ <Home />} />
          <Route exact  path={"/home"} element={ <Home />} />
          <Route path={"/aboutus"} element={ <Aboutus />} />
          <Route path={"/ourproducts"} element={ <Ourproducts />} />
        </Routes>
      </HashRouter >
    </div>
  );
}

export default App;
