import Header from '../comon/header';
import Footer from '../comon/footer';
import bannerimg from '../../assets/images/banner.jpg'
import bannerimg2 from '../../assets/images/aboutusbanner.jpg'
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import icon from '../../assets/images/apple.svg'
import icon1 from '../../assets/images/hp.svg'
import icon2 from '../../assets/images/dell.svg'
import icon3 from '../../assets/images/lenovo.png'
import icon4 from '../../assets/images/asus.png'
import icon5 from '../../assets/images/acer.png'
import mac from '../../assets/images/macbook.webp'
import iphone from '../../assets/images/iphone.webp'
import tab from '../../assets/images/tablet.jpg'

// Import Swiper styles
import { Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-fade';

export default function Home() {
    return (
        <div>
            <Header />

            <section className='banner-section'>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    effect="fade"
                    speed={3000}
                    loop={true}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, EffectFade]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <img className='banner-img' src={bannerimg} alt='' />
                        <div className='banner-details'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <h1 className='title'>
                                            <span className='top'>Experience</span>
                                            <span className='bottom'>The power of renewal</span>
                                        </h1>
                                        <div className='subtitle'>where every click brings you closer to quality, affordability, and innovation. Discover your next journey with our refurbished laptops, where technology meets trust.</div>
                                        <Link className='btn btn-theme' to="/ourproducts" >Discover More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <img className='banner-img' src={bannerimg2} alt='' />
                        <div className='banner-details'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <h1 className='title'>
                                            <span className='top'>Experience</span>
                                            <span className='bottom'>The power of renewal</span>
                                        </h1>
                                        <div className='subtitle'>where every click brings you closer to quality, affordability, and innovation. Discover your next journey with our refurbished laptops, where technology meets trust.</div>
                                        <Link className='btn btn-theme' to="/ourproducts" >Discover More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>

            </section>

            <section className='ourproducts-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='title-section'>
                                <div className='title'>Our Products</div>
                            </div>
                            <div className='products-outer'>
                                <Link to={"/ourproducts"} className='card-product'>
                                    <div className='img-outer'>
                                        <img src={mac} alt='laptop' />
                                    </div>
                                    <div className='title'>Laptops</div>
                                </Link>
                                <Link to={"/ourproducts"} className='card-product'>
                                    <div className='img-outer'>
                                        <img src={iphone} alt='smartphone' />
                                    </div>
                                    <div className='title'>Smartphones</div>
                                </Link>
                                <Link to={"/ourproducts"} className='card-product'>
                                    <div className='img-outer'>
                                        <img src={tab} alt='tablet' />
                                    </div>
                                    <div className='title'>Tablets</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='about-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='sec-title'>Hi, we're Dezwell</div>
                            <div className='sec-desc'>We deliver the Highest Quality Electronics at a fair price with a warranty</div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='brands-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='title-section'>
                                <div className='title'>Brands We Deal.</div>
                            </div>

                            <div className='carousal-outer'>
                                <Swiper
                                    slidesPerView={'auto'}
                                    spaceBetween={30}
                                    speed={5000}
                                    loop={true}
                                    autoplay={{
                                        delay: 0,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Autoplay]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide><img className='slider-img' src={icon} alt='sliderimg' /></SwiperSlide>
                                    <SwiperSlide><img className='slider-img' src={icon3} alt='sliderimg' /></SwiperSlide>
                                    <SwiperSlide><img className='slider-img' src={icon1} alt='sliderimg' /></SwiperSlide>
                                    <SwiperSlide><img className='slider-img' src={icon4} alt='sliderimg' /></SwiperSlide>
                                    <SwiperSlide><img className='slider-img' src={icon2} alt='sliderimg' /></SwiperSlide>
                                    <SwiperSlide><img className='slider-img' src={icon5} alt='sliderimg' /></SwiperSlide>
                                </Swiper>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}